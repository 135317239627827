import React from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import { isAuthenticated } from "@redux/selectors/auth";
import { apiGetProfile } from "@redux/action/auth";
import { useSelector } from "react-redux";
import useFirebaseDashcamHook from '@hooks/firebaseDashcam';

// lazy load all the views
const Home = React.lazy(() => import("./pages/home/"));
const Analytics = React.lazy(() => import("./pages/analyticsV2/"));
const Settings = React.lazy(() => import("./pages/settings/"));
const Report = React.lazy(() => import("./pages/reportV2"));
const NotFound = React.lazy(() => import("./pages/notfound/"));
const Login = React.lazy(() => import("./pages/auth/Login"));
const SignUp = React.lazy(() => import("./pages/auth/SignUp"));
const Logout = React.lazy(() => import("./pages/auth/Logout"));
const AiTraining = React.lazy(() => import("./pages/aiTraining"));
const DashcamMonitoringV2 = React.lazy(() => import('./pages/DashcamMonitoringV2'));
const BindedDashcam = React.lazy(() => import("./pages/bindedDashcam"));
const NewBindedDashcam = React.lazy(() => import("./pages/newBindedDashcam"));
const GenerateQrCode = React.lazy(() => import("./pages/generateQrCode"));
const personalization = React.lazy(() => import('./pages/personalization'));
const userManagement = React.lazy(() => import('./pages/userManagement'))
const AssetList = React.lazy(() => import('./pages/assetList'))

// handle auth and authorization
const PrivateRoute = ({
  component: Component,
  roles,
  user,
  isAuthenticated,
  ...rest
}) => {
  const { companies } = useSelector((s) => s);
  useFirebaseDashcamHook();
  React.useEffect(() => {
    if (isAuthenticated && !companies.isLoading && companies.data.length == 0) {
      apiGetProfile();
    }
  }, [companies.data.length, companies.isLoading, isAuthenticated]);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated) {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }

        // check if route is restricted by role
        if (roles && roles.indexOf(user.role) === -1) {
          // role not authorised so redirect to home page
          return <Redirect to={{ pathname: "/" }} />;
        }

        // authorised so return component
        return <Component {...props} />;
      }}
    />
  );
};

const PrivateRouteWithRedux = connect((state) => ({
  user: state.auth.user,
  isAuthenticated: isAuthenticated(state),
}))(PrivateRoute);

const routes = [
  // does not require token
  {
    path: "/login",
    name: "Login",
    exact: true,
    component: Login,
    route: Route,
  },
  {
    path: "/sign-up",
    name: "SignUp",
    exact: true,
    component: SignUp,
    route: Route,
  },
  {
    path: "/logout",
    name: "Logout",
    exact: true,
    component: Logout,
    route: Route,
  },
  {
    path: "/home",
    name: "Home",
    exact: true,
    component: Home,
    route: PrivateRouteWithRedux,
    roles: ["hq", "branch"],
  },
  {
    path: "/analytics",
    name: "Analytics",
    exact: true,
    component: Analytics,
    route: PrivateRouteWithRedux,
    roles: ["hq", "branch"],
  },
  {
    path: "/report",
    name: "Report",
    exact: true,
    component: Report,
    route: PrivateRouteWithRedux,
    roles: ["hq", "branch"],
  },
  {
    path: "/settings",
    name: "Settings",
    exact: true,
    component: Settings,
    roles: ["hq", "branch"],
    route: PrivateRouteWithRedux,
  },
  {
    path: "/ai-training",
    name: "AI Training",
    exact: true,
    component: AiTraining,
    roles: ["hq", "branch"],
    route: PrivateRouteWithRedux,
  },
  {
    path: "/dashcam",
    name: "Camera Monitoring",
    exact: true,
    component: DashcamMonitoringV2,
    roles: ["hq", "branch"],
    route: PrivateRouteWithRedux,
  },
  {
    path: "/binded-dashcam",
    name: "Binded Camera",
    exact: true,
    component: BindedDashcam,
    roles: ["hq", "branch"],
    route: PrivateRouteWithRedux,
  },
  {
    path: "/new-binded-dashcam",
    name: "New Binded Camera",
    exact: true,
    component: NewBindedDashcam,
    route: PrivateRouteWithRedux,
    roles: ["hq", "branch"],
    noTopBar: true,
  },
  {
    path: "/generate-qr",
    name: "Generate QR Code",
    exact: true,
    component: GenerateQrCode,
    roles: ["hq", "branch"],
    route: PrivateRouteWithRedux,
  },
  {
    path: "/user-management",
    name: "User Management",
    exact: true,
    component: userManagement,
    roles: ["hq", "branch"],
    route: PrivateRouteWithRedux,
  },
  {
    path: "/personalization",
    name: "Personalization",
    exact: true,
    component: personalization,
    route: PrivateRouteWithRedux,
  },
  {
    path: "/personalization/:parameter",
    name: "Personalization",
    exact: true,
    component: personalization,
    roles: ["hq", "branch"],
    route: PrivateRouteWithRedux,
  },
  {
    path: "/asset-list",
    name: "Asset List",
    exact: true,
    component: AssetList,
    roles: ["hq", "branch"],
    route: PrivateRouteWithRedux,
  },
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/home" />,
    route: PrivateRouteWithRedux,
  },
  {
    component: () => <NotFound />,
    route: Route,
  },
];

export { routes, PrivateRouteWithRedux };
